import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import VhwAboutsRow from '../components/VhwAboutsRow'


const OverOns = ({ data }) => {
  const vhwabouts = data.vhwabouts.edges

  return (
    <Layout pageData={data.strapiAbout}>
      <Seo title="Over Ons" />
      <div className="container">
        <h1 className="title">Over ons</h1>
        <VhwAboutsRow vhwabouts={vhwabouts} />
      </div>
    </Layout>
  )
}

export default OverOns

export const query = graphql`
  query {
    strapiAbout {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    vhwabouts: allStrapiVhwAbout (
      sort: { order: DESC, fields: created_at }
    ) {
      edges {
        node {
          slug
          name
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  # width: 1600
                  transformOptions: {grayscale: true}
                )
              }
            }
          }
        }
      }
    }
  }
`
