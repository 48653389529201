import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const VhwAboutLink = ({ vhwabout }) => {

  return (
    <div className="card">
      {vhwabout.node.image
        ? <div className="card-image ">
          <div className="image">
            <GatsbyImage
              alt={vhwabout.node.name}
              image={vhwabout.node.image.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
        : null
      }

      <div className="card-content">
        <div className="content">
          <h3 className="title is-3">{vhwabout.node.name}</h3>
          {vhwabout.node.description &&
            <LinesEllipsis
              text={vhwabout.node.description}
              maxLine='3'
              className="mb-3"
            />
          }
          <Link
            to={vhwabout.node.slug}
            className="button is-info"
          >Lees verder</Link>
        </div>
      </div>
    </div>
  )
}

export default VhwAboutLink
