import React from 'react'
import VhwAboutLink from './VhwAboutLink'

const VhwAboutsRow = ({ vhwabouts }) =>(
  <>
  <div className="columns is-multiline">
    {vhwabouts.map((vhwabout, i) =>
      <div className="column is-4" key={i}>
        <VhwAboutLink vhwabout={vhwabout}  />
      </div>
    )}
    </div>
  </>
)

export default VhwAboutsRow
